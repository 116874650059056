<template>
  <div>
    <el-card style="min-height: 45rem">
      <div class="flex-def flex-zCenter flex-cCenter flex-zTopBottom">
        <el-form label-position="left" style="width: 26rem">
          <div style="width: 100%;padding: 1rem 0 2rem;font-weight: 600">总监规则配置</div>
          <el-form-item label="业绩奖励">关系链下新订单
            <el-input-number v-model="form.ye_ji_award_scale"></el-input-number>%
          </el-form-item>
          <el-form-item label="申请条件">佣金达
            <el-input-number v-model="form.up_chief_limit_fee" style="width: 12rem"></el-input-number>元
          </el-form-item>
          <el-form-item align="right">
            <el-button @click="edit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "conf",
  components:{
  },
  data() {
    return {
      form:{
        ye_ji_award_scale:0,
        up_chief_limit_fee:0,
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.shop.plugin.cmo.rule().then(res=>{
        this.form = res;
      })
    },
    edit(){
      this.$u.api.shop.plugin.cmo.ruleEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>